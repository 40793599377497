<template>
  <div>
    <InnerDetail>
      <template v-slot:title>
        {{ $route.meta.title }}
      </template>
      <template v-slot:detail>
        <div class="detail-content">
          <p class="sub-title">项目概述:</p>
          <span>
          中正工单APP实现了驾校端创建问题工单、跟踪工单进度、查看工单反馈，到工单问题解决的服务流程。使得驾校端更加快捷、有效的反馈培训过程中的问题，做到有记录，无遗漏，可追溯的作用。提高服务质量，有效解决计时问题。
          </span>
        </div>
        <div class="detail-img">
          <div v-for="i in 2" :key="i" class="admin-img">
            <img :src="require(`@/assets/img/product/gongdan${i}.jpg`)" alt="">
          </div>
          <div v-for="i in 2" :key="i">
            <img :src="require(`@/assets/img/product/gongdan_mobile${i}.jpg`)" alt="">
          </div>
        </div>
      </template>
    </InnerDetail>
  </div>
</template>
<script>
import InnerDetail from "@/views/product/components/InnerDetail.vue";

export default {
  name: 'workOrderSys',
  components: {InnerDetail},
}
</script>
